import React from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"

/* Images */
import MainLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import Main from "../components/main/main"
import Content from "../components/content/content"
import Logo from "../components/logo/logo"
import ErrorMessage from "../components/error-message/error-message"

export default function NotFound({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.404.title")
  const PageDescription = t("meta.description")

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <Main>
        <Container>
          <Content>
            <Logo logo={MainLogo} />
            <ErrorMessage
              title={t("pages.404.hero.title")}
              subtitle={t("pages.404.hero.subtitle")}
              text={t("pages.404.hero.text")}
            />
          </Content>
        </Container>
      </Main>
    </Wrapper>
  )
}
